import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import { Flex, Image } from "rebass"
import LSJLogo from "../../assets/images/lsj-logo.svg"

import Medusa from "../../services/api"
import StoreContext from "../../context/store-context"

const Container = styled(Flex)`
  width: 100%;
  height: 100vh;
  background-color: #eeece6;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #00003a;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
`

const Payment = () => {
  const { clearCart } = useContext(StoreContext)

  const completeOrder = async () => {
    const cachedCart = localStorage.getItem("medusa::cache")
    const cart = JSON.parse(cachedCart)

    let order

    try {
      // Check if order is created by webhook
      order = await Medusa.orders.retrieveByCartId(cart.id)
    } catch (error) {
      order = await Medusa.cart.completeCart(cart.id)
    }

    if (order) {
      clearCart()
      let params = []

      if ("data" in order.data) {
        params.push(`o=${order.data.data.id}`)
      } else if ("order" in order.data) {
        params.push(`o=${order.data.order.id}`)
      }

      navigate(`/checkout/done?${params.join("&")}`, {
        replace: true,
      })
    } else {
      navigate(`/checkout`, {
        replace: true,
      })
    }
  }

  useEffect(() => {
    completeOrder()
  }, [])

  return (
    <Container>
      <Helmet title={"Making payment..."} />
      <Image height="150px" width="150px" src={LSJLogo} />
    </Container>
  )
}

export default Payment
